// Copied from the Site project: https://framer.com/projects/Site--8fqVJpvFoZgxIjNnhLvt-8Ytli?node=MqPRKryXK&view=code%3AcodeFile%2FHJzxKba

// Check if visitor is in EU
const countries = [
  "BE",
  "EL",
  "LT",
  "PT",
  "BG",
  "ES",
  "LU",
  "RO",
  "CZ",
  "FR",
  "RE",
  "GP",
  "MQ",
  "GF",
  "YT",
  "BL",
  "MF",
  "PM",
  "WF",
  "PF",
  "NC",
  "HU",
  "SI",
  "DK",
  "FO",
  "GL",
  "HR",
  "MT",
  "SK",
  "DE",
  "IT",
  "NL",
  "AW",
  "CW",
  "SX",
  "FI",
  "AX",
  "EE",
  "CY",
  "AT",
  "SE",
  "IE",
  "LV",
  "PL",
  "UK",
  "GB",
  "AI",
  "BM",
  "IO",
  "VG",
  "KY",
  "FK",
  "GI",
  "MS",
  "PN",
  "SH",
  "TC",
  "GG",
  "JE",
  "IM",
]

const isInEUTimezone = () => {
  return Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone?.startsWith("Europe")
}

const isEULocale = () => {
  const locale = navigator.language ?? navigator.languages?.[0]
  if (!locale) return false
  return countries.some((country) => locale.toUpperCase().includes(country))
}

export const inEU = () => {
  return isInEUTimezone() || isEULocale()
}
