import * as React from "react"
import Loading from "./loading"
import { Stack, Button, Text } from "@framerjs/fraction"
import { IUser } from "../util/api"
import Intro from "../components/intro"
import { Divider } from "../components/divider"
import { useSSORequestedClose } from "../util/useSSORequestedClose"

interface IProps {
  user: IUser | null
  onContinue: () => void
  onSwitchAccount: () => void
  onNotLoggedIn: () => void
}

export default function ContinueAs(props: IProps) {
  const [isLoading, setIsLoading] = React.useState(false)
  const shouldCloseTab = useSSORequestedClose()

  if (!props.user) {
    props.onNotLoggedIn()
    return <Loading />
  }

  if (shouldCloseTab) {
    return null
  }

  // eslint-disable-next-line
  const onSubmit = async (e: React.FormEvent<any>) => {
    setIsLoading(true)
    e.preventDefault()
    props.onContinue()
  }

  // eslint-disable-next-line
  const onClickContinueWithDifferentAccount = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    props.onSwitchAccount()
  }

  return (
    <>
      <Intro
        heading="Welcome to Framer"
        text={
          <>
            Design beautiful websites in minutes.
            <br />
            Sign up or sign in to get started.
          </>
        }
      />
      <Stack direction="column" gap={10}>
        <form noValidate action="#" onSubmit={onSubmit}>
          <Stack direction="column" gap={10}>
            <Button type="submit" variant="primary" size="small" loading={isLoading} fluid>
              Continue as {props.user.firstName}
            </Button>
            <Text size="xsmall" align="center">
              Signed in as {props.user.email}
            </Text>
          </Stack>
        </form>

        <Divider />
        <Button
          onClick={onClickContinueWithDifferentAccount}
          background="rgba(255, 255, 255, 0.15)"
          backgroundHover="rgba(255, 255, 255, 0.1)"
          size="small"
          fluid
          color="#fff"
        >
          Sign in with a different account
        </Button>
      </Stack>
    </>
  )
}
