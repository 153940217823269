import React from "react"
import Intro from "../components/intro"

export default function Success() {
  React.useEffect(() => {
    // If there's an opener, we're coming from action of mini bar opening a new tab
    if (window.opener) {
      window.close()
    } else {
      // Notify signup/signin complete to the window that initiated it
      new BroadcastChannel("login_events").postMessage({ apiVersion: 1, type: "success" })
    }
  }, [])

  return <Intro heading="You’re logged in" text="It’s now safe to close this tab." />
}
