import { API_BASE_URL } from "../env"
import { accessTokenClient } from "./accessTokenClient"
import { ApiError } from "./errors"
import { Origin } from "./validation"

export class ErrorWithExtra extends Error {
  // eslint-disable-next-line
  extra: any

  constructor(message?: string) {
    super(message)
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/FAQ#why-doesnt-extending-built-ins-like-error-array-and-map-work
    Object.setPrototypeOf(this, ErrorWithExtra.prototype)
  }
}

interface IFetchOptions {
  useAccessToken?: boolean
}

const apiFetch = async (
  path: string,
  requestInit: RequestInit,
  { useAccessToken = false }: IFetchOptions = {}
): Promise<Response> => {
  try {
    let init: RequestInit = {
      ...requestInit,
      headers: { "Content-Type": "application/json; charset=utf-8" },
      credentials: "include",
    }
    if (useAccessToken) {
      init = await accessTokenClient.withAuthorizationHeader(init)
    }
    return await fetch(`${API_BASE_URL}${path}`, init)
  } catch (e) {
    console.error(e)
    throw new Error(ApiError.NetworkFailure)
  }
}

const getJSON = async (path: string, options?: IFetchOptions): Promise<Response> => {
  return await apiFetch(path, { method: "GET" }, options)
}

// eslint-disable-next-line
const postJSON = async (path: string, body: any, options?: IFetchOptions): Promise<Response> => {
  return await apiFetch(path, { method: "POST", body: JSON.stringify(body) }, options)
}

// eslint-disable-next-line
const putJSON = async (path: string, body: any, options?: IFetchOptions): Promise<Response> => {
  return await apiFetch(path, { method: "PUT", body: JSON.stringify(body) }, options)
}

export interface IApiError {
  error: { message: string; status: number; type: string }
}

// eslint-disable-next-line
export function isApiError(x: any): x is IApiError {
  return "error" in x
}

export enum NextStep {
  CheckEmail = "check-email",
  Redirect = "redirect",
  CompleteSignUp = "complete-signup",
}

interface ICheckEmailResponse {
  readonly next: NextStep.CheckEmail
}

interface IRedirectResponse {
  readonly next: NextStep.Redirect
  readonly extra: { url: string }
}

export interface ICompleteSignupResponse {
  readonly next: NextStep.CompleteSignUp
  readonly extra: {
    readonly email: string
    readonly firstName: string
    readonly lastName: string
  }
}

export type NextStepResponse = ICheckEmailResponse | IRedirectResponse

export interface ISigninParams {
  readonly email: string
  readonly redirect?: string
  readonly inviteId?: string
  readonly origin?: string
  readonly useFallbackEmail?: boolean
  readonly token?: string
  readonly castleToken?: string
}

export const signIn = async (params: ISigninParams): Promise<NextStepResponse> => {
  const { email, redirect, inviteId, origin, useFallbackEmail, token, castleToken } = params
  const response = await postJSON("/auth/v3/signin", {
    email,
    redirect,
    inviteId,
    origin,
    useFallback: useFallbackEmail,
    token,
    castleToken,
  })

  const body = await response.json()
  if (!isApiError(body)) {
    return body
  }

  throw new Error(ApiError.SignInFailure)
}

interface IGoogleSigninParams {
  readonly redirect?: string
  readonly inviteId?: string
  readonly origin?: string
  readonly castleToken?: string
}

export const googleSignIn = async (params: IGoogleSigninParams): Promise<IRedirectResponse> => {
  const response = await postJSON("/auth/v2/signin/google", params)
  const body = await response.json()
  if (!isApiError(body)) {
    return body
  }
  throw new Error(ApiError.SignInFailure)
}

interface ISignUpParams {
  readonly token: string
  readonly firstName: string
  readonly lastName: string
  readonly subscribe: boolean
  readonly origin: Origin
  readonly castleToken?: string
}

export const completeSignUp = async (params: ISignUpParams): Promise<IRedirectResponse> => {
  const response = await postJSON("/auth/v3/signup", params)
  const body: IApiError | IRedirectResponse = await response.json()
  if (isApiError(body)) {
    throw new Error(ApiError.SignUpFailure)
  }
  return body
}

export const getSignUpState = async (token: string): Promise<ICompleteSignupResponse> => {
  const response = await getJSON(`/auth/v3/check/${token}`)

  if (response.status === 404) {
    throw new Error(ApiError.LoginExpired)
  }

  const body: IApiError | ICompleteSignupResponse = await response.json()
  if (isApiError(body)) {
    throw new Error(ApiError.SignUpFailure)
  }

  return body
}

export interface IUserInvite {
  readonly id: string
  readonly email: string
  readonly resourceType: string
  readonly resourceId: string
  readonly redirectTo: string
  // eslint-disable-next-line
  readonly resource: { [key: string]: any }
}

export const getInvite = async (inviteId: string): Promise<IUserInvite> => {
  const response = await getJSON(`/auth/signin/check/invite/${inviteId}`)
  const body: IApiError | IUserInvite = await response.json()
  if (isApiError(body)) {
    throw new Error(ApiError.InviteFailure)
  }
  return body
}

export const acceptInvite = async (inviteId: string): Promise<boolean> => {
  const response = await postJSON("/auth/signin/invite", { inviteId })
  // eslint-disable-next-line
  const body: IApiError | any = await response.json()

  if (response.status === 403) {
    const error = new ErrorWithExtra(ApiError.InviteDomainRestricted)
    const [, domainsAsString] = body.error.message.split("Accepted domains:")
    const domains = domainsAsString.trim().split(", ")
    error.extra = { domains }
    throw error
  }

  if (isApiError(body)) {
    throw new Error(ApiError.InviteFailure)
  }
  return true
}

export interface IUser {
  readonly id: string
  readonly email: string
  readonly firstName: string
  readonly lastName: string
}

export const getLoggedInUser = async (): Promise<IUser | null> => {
  const response = await getJSON("/auth/signin")
  const body: IUser | IApiError = await response.json()
  return isApiError(body) ? null : body
}

interface TokenPayload {
  readonly userId: string
  readonly payload: {
    readonly avatar: string | null
    readonly email: string
    readonly firstName: string
    readonly lastName: string
    readonly username: string
  }
}

// eslint-disable-next-line
function isTokenPayload(x: any): x is TokenPayload {
  return "payload" in x && "email" in x.payload && "firstName" in x.payload
}

export const getUserInfo = async ({ refresh = false }: { refresh?: boolean } = {}): Promise<IUser | null> => {
  let accessToken: string | null

  try {
    accessToken = await accessTokenClient.getAccessToken({ refresh })
    if (!accessToken) {
      return null
    }
  } catch (err) {
    console.warn("getUserInfo: could not fetch token", err)
    return null
  }

  try {
    // TODO: Move this to AccessTokenClient?
    const parsed = parseJwt(accessToken)
    if (!isTokenPayload(parsed)) {
      return null
    }

    return {
      id: parsed.userId,
      email: parsed.payload.email,
      firstName: parsed.payload.firstName,
      lastName: parsed.payload.lastName,
    }
  } catch (err) {
    console.warn("getUserInfo: could not decode token", err)
    return null
  }
}

// eslint-disable-next-line
function parseJwt(token: string): object {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

export interface IDomainConfiguration {
  domain: string
  isPrivateDomain: boolean
  isAutoJoin: boolean
}

export interface IConfiguration {
  autoJoinTeam: boolean
  autoJoinDefaultRole: "editor" | "viewer"
  // There's more, but we don't need them right now.
}

export interface ITeam {
  id: string
  displayName: string
  avatar: string | null
  domainConfigurations: IDomainConfiguration[]
  configuration: IConfiguration
  // There's more, but we don't need them right now.
}

/**
 * @returns Created team
 */
export const createTeam = async ({ teamName }: { teamName: string }): Promise<ITeam> => {
  try {
    const response = await postJSON("/auth/teams", { teamName }, { useAccessToken: true })
    if (!response.ok) {
      throw new Error("createTeam response is not ok")
    }
    const body: ITeam | IApiError = await response.json()
    if (isApiError(body)) {
      throw new Error("createTeam responded with an API error")
    }
    return body
  } catch {
    // No matter the actual error, let's re-throw as CreateTeamFailure, so that
    // we end up showing an error message specific to team creation — instead of,
    // say, a generic NetworkFailure and "We were unable to sign you in."
    throw new Error(ApiError.CreateTeamFailure)
  }
}

export const updateTeam = async ({
  id: teamId,
  configuration,
}: Pick<ITeam, "id" | "configuration">): Promise<ITeam> => {
  try {
    const response = await putJSON(`/auth/teams/${teamId}`, { configuration }, { useAccessToken: true })
    if (!response.ok) {
      throw new Error("updateTeam response is not ok")
    }
    const body: ITeam | IApiError = await response.json()
    if (isApiError(body)) {
      throw new Error("updateTeam responded with an API error")
    }
    return body
  } catch {
    // No matter the actual error, let's re-throw as UpdateTeamFailure, so that
    // we end up showing an error message specific to team update — instead of,
    // say, a generic NetworkFailure and "We were unable to sign you in."
    throw new Error(ApiError.UpdateTeamFailure)
  }
}

export const joinTeams = async ({ teamIds }: { teamIds: string[] }): Promise<void> => {
  try {
    const response = await postJSON("/auth/teams/join", { teamIds }, { useAccessToken: true })
    if (!response.ok) {
      throw new Error("joinTeams response is not ok")
    }
  } catch {
    // No matter the actual error, let's re-throw as JoinTeamsFailure, so that
    // we end up showing an error message specific to joining teams — instead of,
    // say, a generic NetworkFailure and "We were unable to sign you in."
    throw new Error(ApiError.JoinTeamsFailure)
  }
}

export const getDomainConfiguration = async (): Promise<IDomainConfiguration> => {
  try {
    const response = await getJSON("/auth/teams/domain", { useAccessToken: true })
    const body: IDomainConfiguration | IApiError = await response.json()
    if (isApiError(body)) {
      throw new Error("getDomainConfiguration responded with an API error")
    }
    return body
  } catch {
    // No matter the actual error, we throw a team creation failure, because
    // that has a nice message and will redirect to the dashboard regardless
    throw new Error(ApiError.CreateTeamFailure)
  }
}

export interface IRecommendedTeam {
  id: string
  displayName: string
  avatar: string | null
}

export const getRecommendedTeams = async (): Promise<IRecommendedTeam[]> => {
  try {
    const response = await getJSON("/auth/teams/recommended", { useAccessToken: true })
    const body: IRecommendedTeam[] | IApiError = await response.json()
    if (isApiError(body)) {
      throw new Error("getRecommendedTeams responded with an API error")
    }
    return body
  } catch {
    // No matter the actual error, let's re-throw as GetRecommendedTeamsFailure,
    // so that we end up showing an error message specific to finding team
    // recommendations — instead of, say, a generic NetworkFailure and "We were
    // unable to sign you in."
    throw new Error(ApiError.GetRecommendedTeamsFailure)
  }
}
interface DefaultTeam {
  id: string
  displayName: string
  spaceId: string
}

type IDefaultProjectLocation = { defaultTeam: DefaultTeam | null }

export const getDefaultProjectLocation = async (): Promise<IDefaultProjectLocation> => {
  try {
    const response = await getJSON("/web/users/default-project-location", { useAccessToken: true })
    const body: IDefaultProjectLocation | IApiError = await response.json()
    if (isApiError(body)) {
      throw new Error("getDefaultProjectLocation responded with an API error")
    }
    return body
  } catch {
    // Do not thow an error as we don't want to interrupt the signup flow due to this request
    return { defaultTeam: null }
  }
}

/** Mobile signup flow */

interface RequestDiscountParams {
  email: string
}
export const requestDiscount = async ({ email }: RequestDiscountParams): Promise<void> => {
  const response = await postJSON("/auth/signup/mobile/discount", { email })
  if (!response.ok) {
    throw new Error(ApiError.DiscountEmailFailure)
  }
}

export interface SendInviteParams {
  invitedEmail: string
  senderName: string
}
export const sendInvite = async ({ invitedEmail, senderName }: SendInviteParams): Promise<void> => {
  const response = await postJSON("/auth/signup/mobile/invite", { invitedEmail, senderName })
  if (!response.ok) {
    throw new Error(ApiError.DiscountEmailFailure)
  }
}

export enum Role {
  Designer = "designer",
  Leadership = "leadership",
  Marketer = "marketer",
  Other = "other",
  Sales = "sales",
}

export enum MinTeamSize {
  One = 1,
  Two = 2,
  Ten = 10,
  Hundred = 100,
  Fifty = 50,
  FiveHundred = 500,
}

export type SignupSurveyPayload =
  | { accountType: "personal" | "other" | "client-freelance" | "client-other" }
  | {
      accountType: "business" | "client-agency"
      role: Role
      minTeamSize: MinTeamSize
    }

export async function postSignupSurvey(survey: SignupSurveyPayload): Promise<boolean> {
  try {
    const response = await postJSON("/web/users/signup-survey", survey, { useAccessToken: true })
    return Boolean(response.ok)
  } catch (e) {
    return false
  }
}
