import { SignupSurveyPayload, triggerLoginPageviewEvent, triggerSignupSurveyEvent } from "@framerjs/framer-events"
import * as React from "react"
import { Route, Switch, useLocation } from "react-router-dom"
import Layout from "./components/layout"
import { DASHBOARD_URL } from "./env"
import { redirectToRoute, replaceWithRoute } from "./history"
import { useReCaptcha } from "./RecaptchaProvider"
import { Routes } from "./routes"
import Activate from "./steps/activate"
import Mobile from "./steps/mobile"
import CompleteSignUp, { ICompleteSignupData } from "./steps/complete-signup"
import ContinueAs from "./steps/continue-as"
import Invite from "./steps/invite"
import Loading from "./steps/loading"
import Errors, { ErrorWithExtra } from "./steps/errors"
import PageNotFound from "./steps/page-not-found"
import SignIn from "./steps/signin"
import Team from "./steps/team"
import Survey from "./steps/survey"
import {
  acceptInvite,
  completeSignUp,
  getDefaultProjectLocation,
  getUserInfo,
  googleSignIn,
  IUser,
  NextStep,
  NextStepResponse,
  signIn,
} from "./util/api"
import captureError from "./util/captureError"
import { ApiError, mapToApiError } from "./util/errors"
import { navigateTo } from "./util/navigate"
import { shouldUseDefaultRedirect } from "./util/shouldUseDefaultRedirect"
import { tracker, trackSignupCompletion } from "./util/tracking"
import { getValidOrigin, isAppOrigin, isValidDestinationUrl } from "./util/validation"
import { getCookie } from "./util/getCookie"
import { isMobile } from "./util/environment"
import { useCastle } from "./CastleProvider"
import Success from "./steps/success"
import { useEffect } from "react"

interface IProps {
  redirect: string | null
  backToFramerUrl: string | null
  error: string | null
  invite: string | null
  isInviteForNewUser: boolean
  source: string | null
  origin: string | null
}

function buildRedirectUrl(redirect: string | null, location: { search: string }) {
  const baseUrl = new URL(redirect && isValidDestinationUrl(redirect) ? redirect : DASHBOARD_URL)
  const currentURLParams = new URLSearchParams(location.search)
  const redirectURLParams = new URLSearchParams(baseUrl.searchParams)

  if (currentURLParams.get("closeAfterSuccess") === "true") {
    redirectURLParams.set("close", "true")
    return `${window.location.origin}?${redirectURLParams.toString()}`
  }

  // If pathname does have more than just a slash, we need to add it to the redirect URL otherwise ignore it.
  return `${baseUrl.origin}${baseUrl.pathname.length > 1 ? baseUrl.pathname : ""}${
    redirectURLParams.toString() ? `?${redirectURLParams.toString()}` : ""
  }`
}

export default function App({
  redirect,
  backToFramerUrl,
  error: initialError,
  invite: inviteId,
  isInviteForNewUser,
  source,
  origin: initialOrigin,
}: IProps) {
  const initError = initialError && new Error(mapToApiError(initialError))
  const [error, setError] = React.useState<ErrorWithExtra | null>(initError || null)
  const [pendingInviteId, setPendingInviteId] = React.useState<string | undefined>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [loggedInUser, setLoggedInUser] = React.useState<IUser | null>(null)
  const [hasClickedSwitchAccount, setHasClickedSwitchAccount] = React.useState(false)
  const [signInEmail, setSignInEmail] = React.useState<string | null>(null)
  const location = useLocation()
  const { generateToken } = useReCaptcha()
  const { createToken } = useCastle()

  const searchParams = new URLSearchParams(location.search)
  const redirectedFromCloseAfterSuccess = searchParams.get("close") === "true"
  const redirectedFromWebLogin = searchParams.get("reason") === "web-login"

  // Where to redirect after signin/signup is complete.
  // This usually comes from a query param passed to the App as a prop, but it
  // might also come from the completeGoogleSignup response
  const [redirectUrl, setRedirectUrl] = React.useState(buildRedirectUrl(redirect, location))
  const origin = getValidOrigin(initialOrigin)

  // Don't check if the user is logged in if:
  // - the user chose to switch account
  // - there was an error
  // - we're logging in the desktop app(s), they can't receive the auth token if they don't complete the flow
  // - we're not rendering the home (/) page
  const skipLoggedInCheck = hasClickedSwitchAccount || error || isAppOrigin(origin) || location.pathname !== Routes.home

  /**
   * Check if the user is already logged in on first load.
   * If already logged in, we show the "continue as" page, which lets the user know
   * they can skip the login process (if they choose to).
   *
   * NOTE: The login interstitial (Continue as <name>) is required to fix an issue
   * where Safari would block sending cookies when opening Framer links that result
   * in an immediate redirect to the login page.
   * The user clicking a button to continue ensures that they don't end up in a
   * "login loop", where Safari blocks cookies required for functioning.
   */
  React.useEffect(() => {
    if (skipLoggedInCheck) {
      return
    }

    const checkLoggedInUser = async () => {
      setIsLoading(true)

      try {
        const user = await getUserInfo()
        setLoggedInUser(user)
        if (user && !redirectedFromCloseAfterSuccess) {
          redirectToRoute(Routes.continueAs)
        }
      } catch (e) {
        // in case of error, just let the user log in as normal.
      } finally {
        setIsLoading(false)
      }
    }

    checkLoggedInUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipLoggedInCheck])

  useEffect(() => {
    // We use the web-login reason to redirect the user to the success (you may close this tab) page after they sign in via magic link
    if (redirectedFromCloseAfterSuccess && redirectedFromWebLogin) {
      redirectToRoute(Routes.success)
    }
  }, [redirectedFromCloseAfterSuccess, redirectedFromWebLogin])

  /** Set up tracking */
  React.useEffect(() => {
    // TODO: Consider refactoring this and the checkLoggedInUser effect above so
    // that we only call getUserInfo once on load.
    //
    // Note that getUserInfo is powered by the AccessTokenClient, which only
    // fetches the token once per its validity period, so the double getUserInfo
    // call should still result in just one network request.
    getUserInfo().then((user) => {
      tracker.setUserId(user?.id ?? null)
    })
  }, [])
  React.useEffect(() => {
    triggerLoginPageviewEvent({
      url: document.location.href,
      referrer: document.referrer,
      hostname: document.location.hostname,
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
      cookieConsent: getCookie("cookie_consent"),
      isMobile: isMobile(),
    })
  }, [location])

  const handleError = React.useCallback((err) => {
    captureError(err)
    setError(err)
  }, [])

  const handleNextStep = (response: NextStepResponse) => {
    switch (response.next) {
      case NextStep.CheckEmail:
        return redirectToRoute(Routes.activate)
      case NextStep.Redirect:
        return navigateTo(response.extra.url)
      default:
        return
    }
  }

  // Handle user entering email and clicking continue
  const initiateEmailSignIn = async (email: string) => {
    try {
      setSignInEmail(email)
      const token = await generateToken("signin")
      const castleToken = await createToken()
      const response = await signIn({
        email,
        redirect: redirectUrl,
        inviteId: pendingInviteId,
        origin,
        token,
        castleToken,
      })
      handleNextStep(response)
    } catch (e) {
      if (e instanceof Error && e.message === ApiError.UserNotFound) {
        // TODO: Display toast indicating reason for this redirect
        redirectToRoute(Routes.createAccount)
        return
      }
      handleError(e)
    }
  }

  // Handle user retrying sending sign in email
  const retryEmailSignIn = async (email: string, useFallbackEmail: boolean) => {
    try {
      const token = await generateToken("signin")
      const castleToken = await createToken()
      await signIn({
        email,
        redirect: redirectUrl,
        inviteId: pendingInviteId,
        origin,
        useFallbackEmail,
        token,
        castleToken,
      })
    } catch (e) {
      if (e instanceof Error && e.message === ApiError.UserNotFound) {
        redirectToRoute(Routes.createAccount)
        return
      }
      handleError(e)
    }
  }

  // Handle "continue with Google"
  const initiateGoogleSign = async () => {
    const castleToken = await createToken()
    try {
      const response = await googleSignIn({
        redirect: redirectUrl,
        inviteId: pendingInviteId,
        origin,
        castleToken,
      })
      handleNextStep(response)
    } catch (e) {
      handleError(e)
    }
  }

  // Handle completing the final step
  const completeSignup = async (data: ICompleteSignupData) => {
    const castleToken = await createToken()
    try {
      const redirectResponse = await completeSignUp({
        token: data.token,
        firstName: data.firstName,
        lastName: data.lastName,
        subscribe: data.newsletter,
        origin,
        castleToken,
      })
      setRedirectUrl(redirectResponse.extra.url)

      trackSignupCompletion({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      })

      const user = await getUserInfo({ refresh: true })
      if (user) {
        setLoggedInUser(user)
        tracker.setUserId(user.id)
      }

      if (isMobile()) {
        replaceWithRoute(Routes.mobile)
        return
      }

      replaceWithRoute(Routes.survey)
    } catch (e) {
      handleError(e)
    }
  }

  const onInvalidInvite = React.useCallback(
    (e: ApiError, isLoggedIn: boolean) => {
      if (isLoggedIn) {
        return navigateTo(redirectUrl)
      }
      handleError(new Error(e))
    },
    [handleError, redirectUrl]
  )

  // Handle loggedin user accepting an invite
  const onAcceptInvite = React.useCallback(
    async (id: string) => {
      try {
        await acceptInvite(id)
        navigateTo(redirectUrl)
      } catch (e) {
        handleError(e)
      }
    },
    [handleError, redirectUrl]
  )

  // Handle already logged in user continuing with their account.
  const onContinueAs = React.useCallback(() => {
    try {
      navigateTo(redirectUrl)
    } catch (e) {
      handleError(e)
    }
  }, [handleError, redirectUrl])

  // Handle choosing to switch account when already logged in.
  const onSwitchAccount = () => {
    setHasClickedSwitchAccount(true)
    redirectToRoute(Routes.signIn)
  }

  // Handle landing trying to continue when not logged in
  const onNotLoggedIn = () => {
    redirectToRoute(Routes.home)
  }

  // Handle switching accounts prior to accepting an invite
  const onAcceptInviteWithDifferentAccount = (id: string) => {
    // Store inviteId in state so that we can include it in future API call payloads
    setPendingInviteId(id)
    const destination = isInviteForNewUser ? Routes.createAccount : Routes.signIn
    redirectToRoute(destination)
  }

  const onSurveyComplete = (payload: SignupSurveyPayload) => {
    triggerSignupSurveyEvent(payload)
    redirectToRoute(Routes.team)
  }

  const onTeamSetupComplete = React.useCallback(
    async (options?: { useDefaultRedirect?: boolean }) => {
      if (redirectedFromCloseAfterSuccess) {
        redirectToRoute(Routes.success)
        return
      }

      const url = new URL(redirectUrl)

      if (options?.useDefaultRedirect || shouldUseDefaultRedirect(url)) {
        // Show the Welcome modal regardless.
        url.searchParams.set("welcome", "1")
        navigateTo(url.href)
        return
      }

      // Enter into a new project
      const defaultURL = new URL(DASHBOARD_URL)
      defaultURL.pathname = "/projects/new"

      try {
        const { defaultTeam } = await getDefaultProjectLocation()
        if (defaultTeam) {
          defaultURL.searchParams.set("team", defaultTeam.spaceId)
        }
      } finally {
        defaultURL.searchParams.set("welcome", "1")
        navigateTo(defaultURL.href)
      }
    },
    [redirectUrl, redirectedFromCloseAfterSuccess]
  )

  const onErrorRetry = () => {
    setError(null)
    redirectToRoute(Routes.home)
  }

  if (isLoading) {
    return (
      <Layout backToFramerUrl={backToFramerUrl}>
        <Loading />
      </Layout>
    )
  }

  return (
    <Layout backToFramerUrl={backToFramerUrl}>
      {error && <Errors onRetry={onErrorRetry} isAppError={source === "app"} error={error} />}
      {!error && (
        <Switch>
          {/* Sign in page */}
          <Route exact path={[Routes.signIn, Routes.home, Routes.createAccount]}>
            <SignIn onEmailValidated={initiateEmailSignIn} initiateGoogleSign={initiateGoogleSign} />
          </Route>

          {/* Logged in, opportunity to switch account */}
          <Route exact path={Routes.continueAs}>
            <ContinueAs
              user={loggedInUser}
              onContinue={onContinueAs}
              onSwitchAccount={onSwitchAccount}
              onNotLoggedIn={onNotLoggedIn}
            />
          </Route>

          {/* Returning to create account */}
          <Route exact path={`${Routes.completeSignUp}:token/`}>
            <CompleteSignUp onError={handleError} onComplete={completeSignup} />
          </Route>

          {/* Team recommendations and creation */}
          <Route path={Routes.team}>
            <Team onComplete={onTeamSetupComplete} onError={handleError} loggedInUserEmail={loggedInUser?.email} />
          </Route>

          {/* Signup survey */}
          <Route path={Routes.survey}>
            <Survey onComplete={onSurveyComplete} />
          </Route>

          {/* Mobile signup flow */}
          <Route path={Routes.mobile}>
            <Mobile loggedInUser={loggedInUser} />
          </Route>

          {/* This is to prevent URLs being directly accessible */}
          <Route exact path={Routes.activate}>
            <Activate email={signInEmail} onResendEmailClicked={retryEmailSignIn} />
          </Route>

          {/* Success screen */}
          <Route exact path={Routes.success}>
            <Success />
          </Route>

          {inviteId && (
            <Route exact path={Routes.invite}>
              <Invite
                inviteId={inviteId}
                onInvalidInvite={onInvalidInvite}
                onAcceptInvite={onAcceptInvite}
                onSwitchAccount={onAcceptInviteWithDifferentAccount}
              />
            </Route>
          )}
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      )}
    </Layout>
  )
}
