import { useEffect } from "react"
import { useLocation } from "react-router"

export function useSSORequestedClose() {
  const location = useLocation()
  const currentURLParams = new URLSearchParams(location.search)

  const ssoRequestedClose = currentURLParams.get("close") === "true"
  const shouldClose = ssoRequestedClose && window.opener
  useEffect(() => {
    if (shouldClose) {
      window.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClose])

  return shouldClose
}
