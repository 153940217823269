import * as React from "react"
import Intro from "../components/intro"
import styled, { keyframes } from "styled-components"
import { A, Span, Text } from "@framerjs/fraction"
import { useLocation } from "react-router-dom"

const Envelope = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="39" height="30">
    <path
      d="M 0 4.5 C 0 2.015 2.015 0 4.5 0 L 34.5 0 C 36.985 0 39 2.015 39 4.5 L 39 25.5 C 39 27.985 36.985 30 34.5 30 L 4.5 30 C 2.015 30 0 27.985 0 25.5 Z"
      fill="#09F"
    />
    <path d="M 0 3 L 18.75 21 L 39 3 L 18.75 16.5 Z" fill="#FFF" />
  </svg>
)

interface IProps {
  onResendEmailClicked: (email: string, useFallbackEmail: boolean) => void
  email: string | null
}

export default function Activate({ email: prefillEmail, onResendEmailClicked }: IProps) {
  const [resendAllowed, setResendAllowed] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const closeAfterSuccess = searchParams.get("closeAfterSuccess") === "true"

  // Listen for the success event from the magic link opened in a new tab
  React.useEffect(() => {
    if (closeAfterSuccess) {
      new BroadcastChannel("login_events").onmessage = (e: MessageEvent) => {
        if (e.data.type === "success") {
          window.close()
        }
      }
    }
  }, [closeAfterSuccess])

  React.useEffect(() => {
    // Added delay before showing the resend email link
    if (!prefillEmail) return

    const timer = setTimeout(() => {
      setResendAllowed(true)
    }, 10000)

    return () => clearTimeout(timer)
  }, [prefillEmail])

  const resendEmail = async () => {
    if (!prefillEmail) return

    setIsLoading(true)
    await onResendEmailClicked(prefillEmail, true)
    setIsLoading(false)
    setResendAllowed(false)
  }

  return (
    <>
      <Intro
        heading="Check your inbox"
        icon={<Envelope />}
        text={
          <>
            We sent you an activation link. Please
            <br />
            be sure to check your spam folder too.
          </>
        }
        trim
      />

      {resendAllowed && (
        <Subtitle>
          <A onClick={isLoading ? undefined : resendEmail}>
            <DelayedCallToAction>Click here</DelayedCallToAction>
          </A>
          <DelayedText> if you didn’t receive the email.</DelayedText>
        </Subtitle>
      )}
    </>
  )
}

const fadeInText = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
`

const fadeInCallToAction = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Subtitle = styled(Text)`
  color: rgba(255, 255, 255);
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
`

const DelayedText = styled(Span)`
  animation: ${fadeInText} 0.4s ease-in-out forwards;
`

const DelayedCallToAction = styled(Span)`
  font-weight: 500;
  animation: ${fadeInCallToAction} 0.4s ease-in-out forwards;
`
