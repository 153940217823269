function getEnv(env: string): string | undefined {
  return (window.ENV && window.ENV[env]) || process.env[env]
}

function getEnvWithDefault(env: string, defaultValue: string): string {
  return getEnv(env) || defaultValue
}

function getBooleanEnv(env: string): boolean {
  return getEnv(env) === "true"
}

function getOptionalJsonEnv(env: string): Record<string, unknown> {
  try {
    return JSON.parse(getEnvWithDefault(env, "{}"))
  } catch (error) {
    return {}
  }
}

export const API_BASE_URL = getEnvWithDefault("REACT_APP_API_BASE_URL", "https://api.framer.com")
export const DASHBOARD_URL = getEnvWithDefault("REACT_APP_DASHBOARD_URL", "https://framer.com")
export const EVENTS_URL = getEnvWithDefault("REACT_APP_EVENTS_URL", "https://events.framer.com")

export const SENTRY_RELEASE = getEnv("REACT_APP_SENTRY_RELEASE")
export const SENTRY_ENV = getEnvWithDefault("REACT_APP_SENTRY_ENV", "local")
export const ALLOW_LOCALHOST_REDIRECT = getBooleanEnv("REACT_APP_ALLOW_LOCALHOST_REDIRECT")

export const RECAPTCHA_SITE_KEY = getEnv("REACT_APP_RECAPTCHA_SITE_KEY")
export const CASTLE_PUBLISHABLE_API_KEY = getEnv("REACT_APP_CASTLE_PUBLISHABLE_API_KEY")

export const GTM_URL = getEnv("REACT_APP_GTM_URL")

export const DUB_COOKIE_OPTIONS = getOptionalJsonEnv("REACT_APP_DUB_COOKIE_OPTIONS")
